/*! Basic Scripts 17.08.2022 */

/*----------------------------------------
  Shrinking Header
----------------------------------------*/
$(document).on("scroll", function(){
  if ($(document).scrollTop() > 30){
    $("header").addClass("shrink");
  } else {
    $("header").removeClass("shrink");
  }
});


/*----------------------------------------
  Fixed Footer
----------------------------------------*/
$(document).ready(function(){
  function fullmainwindow(){
    var intMinMainHeight = jQuery(window).height() - $('#header').outerHeight() - $('#footer').outerHeight();
    if ($('#main').outerHeight() < intMinMainHeight) {
      $("#main").css('minHeight', intMinMainHeight);
    }
  }
  fullmainwindow();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    fullmainwindow();
  });
});


/*----------------------------------------
  Top-Link
----------------------------------------*/
$(document).ready(function() {
  $('div.toplink').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.toplink').fadeIn();
    } else {
      $('div.toplink').fadeOut();
    }
  });
  $('div.toplink').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});


/*----------------------------------------
  Navbar Mobile Toggler
----------------------------------------*/
$(document).ready(function() {
  $('.navbar-toggler').click(function() {
    $(".navbar-toggler").toggleClass("navbar-toggler--active");
    $(".nav--mobile").toggleClass("nav--mobile--active");
  });

  $('.nav--mobile li a').click(function() {
    $(".navbar-toggler").toggleClass("navbar-toggler--active");
    $(".nav--mobile").toggleClass("nav--mobile--active");
  });
});


/*----------------------------------------
  Onepage-Navigation
----------------------------------------*/
$(document).ready(function () {
  var el = $('a[href*="#"]').not('[href="#"]').not('[href="#0"]');
  var scrollSpeed = 800;
  var uri = window.location.href.split("#")[0];

  el.click(function (event) {
    // check if On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 50 }, scrollSpeed);
        history.pushState("", "", uri + this.hash);
      }
    }
  });

  // set nav active when scrolling
  var navActive = function (section) {
    var $el = $('.onepage_navigation__list');

    $el.find('li.active').removeClass('active');
    $el.each(function () {
      $(this).find('a[data-onepagelink="' + section + '"]').parent().addClass('active');
    });
  };

  var navigationSection = function () {
    var $section = $('.mod_article');

    $section.waypoint(function (direction) {

      if (direction === 'down') {
        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);
        }
      }
    }, {
      offset: '50%'
    });

    $section.waypoint(function (direction) {

      if (direction === 'up') {
        var previousWaypoint = this.previous();

        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);
        }
      }

    }, {
      offset: function () {
        return (this.context.innerHeight() / 2) - this.element.offsetHeight; // offset 50% from bottom
      },
      group: $section
    });
  };

  $(function () {
    navigationSection();
  });

});


/*----------------------------------------
  Form width placeholder
----------------------------------------*/
$("form :input").each(function(index, elem) {
  var eId = $(elem).attr("id");
  var label = null;
  if (eId && (label = $(elem).parents("form").find("label[for=" + eId + "]")).length == 1) {
    if (!$(elem).hasClass("captcha")) {
      var placeholder = $(label).text();
      var tmp_placeholder = placeholder.replace("Pflichtfeld", "");
      var new_placeholder = tmp_placeholder.trim();
      $(elem).attr("placeholder", new_placeholder);
      $(label).addClass('hide');
    }
  }
});
$("form :input").focus(function() {
  $(this).parent(".widget").each(function() {
    $("label", this).removeClass("hide");
    $("label", this).addClass("active");
  });
}).blur(function() {
  if ($(this).val() === "") {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
      $("label", this).addClass("hide");
    });
  } else {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
    });
  }
});
